import React from 'react';
import './MobileMenu.scss';

class MobileMenu extends React.PureComponent {
  render() {
    return (
      <div className="mobile-menu">
        mobile menu
      </div>
    );
  }
}

export default MobileMenu;
