import React from 'react';
import PropTypes from 'prop-types';
import D3Slider from '../d3sliderRange/D3SliderRange';

class SidebarVulnerabilityLayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.d3Slider = null;
    this.state = {
      sliderWidth: null,
      sliderHeight: null
    };
  }

  componentDidMount() {
    this.sliderNode = this.sliderRef.current;
    this.setDimensions();
  }

  componentDidUpdate() {
    if (this.d3Slider === null) {
      this.drawSlider();
    } else {
      this.updateSlider();
    }
  }

  setDimensions() {
    if (this.sliderNode !== undefined) {
      const { width, height } = this.sliderNode.getBoundingClientRect();
      this.setState({
        sliderWidth: width,
        sliderHeight: height
      });
    }
  }

  drawSlider() {
    const {
      value,
      maxValue,
      minValue,
      id,
      setChoroplethValue,
      slider,
      colorRamp
    } = this.props;

    const { sliderWidth, sliderHeight } = this.state;

    if (sliderWidth === null) return;

    const trackHeight = sliderHeight - 5;

    this.d3Slider = new D3Slider({
      axisOn: false,
      trackHeight,
      colorRamp,
      slider,
      width: sliderWidth,
      height: sliderHeight,
      padding: {
        left: 5,
        right: 10
      },
      handleCornerRadius: 5,
      trackCornerRadius: 5,
      handleHeight: sliderHeight,
      handleWidth: 14,
      timelineNode: this.sliderNode,
      currentValue: value,
      valueRange: [minValue, maxValue],
      setRange: d => {
        setChoroplethValue(id, d);
      }
    });
    this.d3Slider.init();
  }

  updateSlider() {
    const { value } = this.props;
    this.d3Slider.updateValue(value);
  }

  render() {
    return (
      <div className="sidebar__choropleth-slider-row">
        <div className="sidebar__choropleth-slider-labels">
          <div className="sidebar__choropleth=slider-label">Low</div>
          <div className="sidebar__choropleth=slider-label">High</div>
        </div>
        <div className="sidebar__choropleth-slider" ref={this.sliderRef} />
      </div>
    );
  }
}

SidebarVulnerabilityLayer.defaultProps = {
  colorRamp: null
};

SidebarVulnerabilityLayer.propTypes = {
  /** Sets choropleth filter values */
  setChoroplethValue: PropTypes.func.isRequired,
  /** choropleth data item id */
  id: PropTypes.string.isRequired,
  /** layer name */
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  colorRamp: PropTypes.arrayOf(PropTypes.string)
};

export default SidebarVulnerabilityLayer;
