import React from 'react';

const AreaIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 19 20"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      className="sidebar__area-icon"
      d="M17.5,11c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.1-0.5-0.1-0.8,0c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.2-0.1,0.5,0,0.8
      l-0.2,0.1l-0.2-0.6c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.1-0.5-0.1-0.8,0c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.2-0.1,0.5,0,0.8l0.2,0.6
      l-0.2,0.1l-0.4-1c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.1-0.5-0.1-0.8,0c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.2-0.1,0.5,0,0.8l0.3,1l-0.2,0.1
      L9.5,8.5C9.4,8.2,9.2,8,9,7.9c-0.2-0.1-0.5-0.1-0.8,0C7.9,8,7.7,8.1,7.6,8.4c-0.1,0.2-0.1,0.5,0,0.8l2.4,6.6L8.8,15
      c-0.2-0.2-0.5-0.2-0.7-0.1c-0.3,0-0.5,0.2-0.7,0.4c-0.2,0.2-0.2,0.5-0.2,0.8c0.1,0.3,0.2,0.5,0.4,0.7l4.6,3.1
      c0.3,0.2,0.6,0.2,0.9,0.1l5-1.8c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.1-0.7l-0.4-3.5c0-0.1,0-0.2-0.1-0.2L17.5,11z M12.1,15.1
      l0.8,2.3l-0.2,0.1l-0.8-2.3L12.1,15.1z M14.2,14.3l0.8,2.3l-0.2,0.1L14,14.4L14.2,14.3z M16.3,13.6l0.8,2.3L17,15.9l-0.8-2.3
      L16.3,13.6z M14,0v8h-1V1H1v12h4v1H0V0H14z"
    />
  </svg>
);

export default AreaIcon;
