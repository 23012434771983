import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelUp,
  faToggleOff,
  faToggleOn
} from '@fortawesome/pro-regular-svg-icons';
import SidebarVulnerabilitySlider from './SidebarVulnerabilitySlider';

import SwatchIcon from './SidebarSwatchIcons';

class SidebarVulnerabilityLayer extends React.PureComponent {
  getTitleSwitch() {
    const { hidden, setSelectedThematicLayer, id } = this.props;

    const icon = hidden ? faToggleOff : faToggleOn;
    return (
      <FontAwesomeIcon
        icon={icon}
        onClick={() => setSelectedThematicLayer(id)}
      />
    );
  }

  getTitleRow() {
    const { name } = this.props;

    return (
      <div className="sidebar__choropleth-layer-name sidebar__layers-title-row">
        <div className="sidebar__layers-title-left">
          <FontAwesomeIcon icon={faLevelUp} rotation={90} />
          <div className="sidebar__layers-title">
            <div className="sidebar__layers-title">{name}</div>
          </div>
        </div>
        <div className="sidebar__layers-title-right">
          {this.getTitleSwitch()}
        </div>
      </div>
    );
  }

  getSliderRow() {
    const {
      setChoroplethValue,
      id,
      maxValue,
      minValue,
      value,
      slider,
      colorRamp
    } = this.props;

    const sliderProps = {
      setChoroplethValue,
      id,
      maxValue,
      minValue,
      value,
      slider,
      colorRamp
    };

    return <SidebarVulnerabilitySlider {...sliderProps} />;
  }

  drawLayerRow(layer) {
    const { setHighlightedLayer, highlightedLayer } = this.props;

    let buttonClass = 'sidebar__layer-button';
    if (highlightedLayer === layer.id) {
      buttonClass += ` ${buttonClass}--highlighted`;
    }

    return (
      <div className="sidebar__layer-row" key={layer.id}>
        <div
          className={buttonClass}
          onClick={() => setHighlightedLayer(layer.id)}
        >
          <div className="sidebar__layer-button-inner">{layer.title}</div>
        </div>
        <SwatchIcon color={layer.swatch} geometry="polygon" />
      </div>
    );
  }

  drawLayerRows() {
    const { types } = this.props;
    return types.map(layer => this.drawLayerRow(layer));
  }

  render() {
    const { slider } = this.props;
    return (
      <div className="sidebar__choropleth-layer-row sidebar__layers-block">
        {this.getTitleRow()}
        {slider ? this.getSliderRow() : this.drawLayerRows()}
      </div>
    );
  }
}

SidebarVulnerabilityLayer.defaultProps = {
  colorRamp: null
};

SidebarVulnerabilityLayer.propTypes = {
  /** currently highlighted layer */
  highlightedLayer: PropTypes.string,
  /** Callbak to set app `hiddenLayers` state field */
  setSelectedThematicLayer: PropTypes.func.isRequired,
  /** callback to set highlightedLayer (layer id/name) */
  setHighlightedLayer: PropTypes.func.isRequired,
  /** color ramp for slider background */
  colorRamp: PropTypes.arrayOf(PropTypes.string),
  /** if layer is on/off */
  hidden: PropTypes.bool.isRequired,
  /** Sets choropleth filter values */
  setChoroplethValue: PropTypes.func.isRequired,
  /** choropleth data item id */
  id: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  /** layer name */
  slider: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** callback to turn layer on/off */
  toggleLayerVisibility: PropTypes.func.isRequired
};

export default SidebarVulnerabilityLayer;
